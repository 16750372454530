import React, { useState } from "react";
import { navigate, Link } from "gatsby";
import axios from "axios";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import { handleLogin, mutationLogin } from "../../services/auth";
import { isBrowser } from "../../helpers";

export default function Login({ data }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();

    if (!isBrowser) return false;

    setProcessing(true);

    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        mutationLogin({ login, password }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.errors) {
          const { message } = res.data.errors[0];
          if (
            message === "incorrect_password" ||
            message === "invalid_username" ||
            message === "invalid_email"
          ) {
            setError("Incorrect login details");
            setProcessing(false);
          }

          return null;
        }

        setError("");
        setProcessing(false);

        const {
          firstName = "",
          lastName = "",
          email = "",
          username,
        } = res.data.data.login.user;

        handleLogin({
          login: username,
          firstName,
          lastName,
          email,
          id: res.data.data.login.user.id,
          group: res.data.data.login.user.userGroup?.group.slug,
          token: res.data.data.login.authToken,
          refreshToken: res.data.data.login.refreshToken,
        });

        navigate("/account/price-lists");
      });
  }

  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: "/account/login/",
      }}
      path={"a"}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          backgroundColor: "#19365B",
          margin: "50px auto",
          maxWidth: 768,
          padding: 32,
        }}
      >
        <h3
          style={{
            color: "white",
            fontWeight: "normal",
            letterSpacing: "0.016em",
            fontSize: 32,
            marginTop: 0,
            marginBottom: 12,
          }}
        >
          Login to your account
        </h3>
        <p
          style={{
            color: "white",
          }}
        >
          Please complete your login details below to view your secure area:
        </p>
        <label
          htmlFor="login"
          style={{
            color: "white",
            fontSize: 12,
            lineHeight: 1.6,
            letterSpacing: "0.152em",
            margin: 0,
            fontFamily: "prometo, sans-serif",
          }}
        >
          Login
        </label>
        <input
          name="login"
          type="text"
          placeholder="Login"
          onChange={(e) => setLogin(e.target.value)}
          style={{
            fontFamily: "prometo, sans-serif",
            backgroundColor: "transparent",
            borderRadius: 20,
            fontSize: 12,
            lineHeight: 2,
            padding: "7px 20px",
            border: "1px solid white",
            marginBottom: 24,
            color: "white",
            outline: "none",
            display: "block",
            width: "100%",
          }}
        />
        <label
          htmlFor="password"
          style={{
            color: "white",
            fontSize: 12,
            lineHeight: 1.6,
            letterSpacing: "0.152em",
            margin: 0,
            fontFamily: "prometo, sans-serif",
          }}
        >
          Password
        </label>
        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          style={{
            fontFamily: "prometo, sans-serif",
            backgroundColor: "transparent",
            borderRadius: 20,
            fontSize: 12,
            lineHeight: 2,
            padding: "7px 20px",
            border: "1px solid white",
            marginBottom: 24,
            color: "white",
            outline: "none",
            display: "block",
            width: "100%",
          }}
        />
        {processing && (
          <div
            className="logging-in"
            style={{
              color: "white",
              fontFamily: "prometo, sans-serif",
              letterSpacing: "0.006em",
              marginBottom: 16,
            }}
          >
            Processing
          </div>
        )}
        {!!error && (
          <div
            style={{
              marginBottom: 16,
              display: "block",
              backgroundColor: "#c8102e",
              color: "white",
              fontFamily: "prometo, sans-serif",
              padding: "12px 24px",
              fontSize: 14,
              letterSpacing: "0.012em",
              borderRadius: 2,
            }}
          >
            {error}
          </div>
        )}
        <input
          type="submit"
          value="Submit"
          style={{
            width: "auto",
            backgroundColor: "white",
            color: "rgb(15,28,50)",
            fontFamily: "prometo, sans-serif",
            fontSize: 14,
            display: "block",
            padding: "10px 20px",
            letterSpacing: "0.016em",
            fontWeight: "bold",
            textAlign: "center",
            backgroundPosition: "center right 24px",
            backgroundRepeat: "no-repeat",
            outline: "none",
            boxShadow: "0 8px 10px rgb(0 0 0 0.8)",
            cursor: "pointer",
            borderRadius: 25,
            border: "none",
          }}
        />
        <p
          style={{
            color: "white",
          }}
        >
          Not a member?{" "}
          <Link to="/account/create" style={{ color: "white" }}>
            Register now.
          </Link>
        </p>
        <p
          style={{
            color: "white",
          }}
        >
          If you have forgotten your password or require any further help
          logging in, please contact{" "}
          <a href="mailto:marketing@tyde.co.uk" style={{ color: "white" }}>
            marketing@tyde.co.uk
          </a>
        </p>
      </form>
    </Layout>
  );
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
